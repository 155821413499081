.pro-sidebar {
  z-index: 1050;
  transition: width, left, right, 0.2s;
  // margin-right: 0.75rem;

  &.collapsed {
    top: 0;
    .pro-sidebar-content {
      li {
        &:first-child {
          visibility: hidden;
        }
      }
    }
  }

  &.toggled {
    width: 220px;
    min-width: 220px;

    .pro-sidebar-header {
      .btn-nav-toggle {
        display: inline-block;
        left: 180px;
      }
    }
    .pro-sidebar-content {
      li {
        &:first-child {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  .pro-sidebar-header {
    border-bottom: 1px solid transparent !important;
    .logo {
      margin: 1.25rem 0 1.5rem 1.75rem;
    }
    .btn-nav-toggle {
      // display: none;
      border-radius: 50%;
      padding: 0 0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      // position: fixed !important;
      position: relative;
      right: 0;
      top: 8px;
      transition: left, 0.2s;
      line-height: 0.5;
      // box-shadow: 0 0 0 1px rgb(9 30 66 / 8%), 0 2px 4px 1px rgb(9 30 66 / 8%);
      color: rgba($color: #c4c4c4, $alpha: 0.5);
      background-color: #1d2530;
      border-color: #1d2530;

      &:focus {
        background-color: #1d2530;
        border-color: #1d2530;
        box-shadow: 0 0 0 0.1rem rgba($pure-black, 0.1);
      }
    }
  }

  .pro-sidebar-content {
    li {
      &:first-child {
        visibility: hidden;

        .pro-inner-item {
          color: rgba($color: #c4c4c4, $alpha: 0.375);
        }
        .pro-inner-item:focus {
          color: rgba($color: #c4c4c4, $alpha: 0.375);
        }
        .pro-inner-item:hover {
          color: rgba($color: #ffffff, $alpha: 1);
        }
      }
    }
  }

  &:hover {
    .pro-sidebar-content {
      li {
        &:first-child {
          visibility: hidden;
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .pro-sidebar {
    z-index: 1050;

    .pro-sidebar-header {
      .btn-nav-toggle {
        display: none;
      }
    }

    .pro-sidebar-content {
      li {
        &:first-child {
          display: list-item;
          transition: visibility, 0.5s;
          visibility: visible;
        }
      }
    }

    &:hover {
      .pro-sidebar-content {
        li {
          &:first-child {
            visibility: visible;
          }
        }
      }
    }
  }
}
