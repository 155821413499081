// Extra small devices (portrait phones, less than 576px)

.explore {
  overflow: visible;

  &.asset-detail {
    // margin-top: 2rem;
    // margin-bottom: 0;
    padding-top: 2rem;
    // padding-bottom: 2rem;
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);

    .asset-info {
      text-align: center;

      .company-icon-container {
        min-height: 96px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;

        img {
          border-radius: 5px;
          margin-top: 1rem;
          margin-bottom: 1.25rem;
          max-width: 60px;
        }
      }

      p {
        transition: all 0.3s ease-in-out;
        .btn {
          background: linear-gradient(0deg, rgba(13, 20, 30, 1) 40%, rgba(13, 20, 30, 0) 100%);
          border: none;
          position: relative;
          bottom: 3.5rem;
          height: 3.5rem;
          width: 100%;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: -3.5rem;

          &:focus {
            box-shadow: 0 0 0 0.1rem rgba($pure-black, 0.1);
          }
        }

        &.open {
          .btn {
            display: none;
            background: none;
            border: none;
            bottom: 0.5rem;
          }
        }
      }

      h5 {
        margin-bottom: 1.5rem;
      }

      p.lead {
        margin-top: 3rem;
      }

      .d3-chart {
        width: 100%;
      }

      .asset-stats {
        padding-bottom: 4rem;
      }

      .asset-stat {
        border: 1px solid #202832;
        border-radius: 0.5rem;
        padding: 1rem;
        height: 100%;
        margin-bottom: 1rem;

        h3 {
          font-size: 3rem;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .minifolio {
      text-align: center;
      padding-top: 4rem;
      padding-bottom: 4rem;
      background-color: rgba(255, 255, 255, 0.1);

      h3 {
        margin-bottom: 2rem;
      }

      p {
        margin-bottom: 2rem;
      }

      .btn {
        background-color: $brand-green;
        border-color: $brand-green;
        padding: 0.75rem 1.5rem;
        margin-top: 3rem;

        svg {
          margin-right: 0.25rem;
        }

        &:hover {
          background-color: $brand-orange;
          border-color: $brand-orange;
        }
      }

      .folio-combo {
        margin-top: 1rem;
        border: 1px solid #202832;
        border-radius: 0.5rem;
        padding: 2rem 1rem 1rem;
        background-color: #0c121b;

        strong {
          font-size: 1.5rem;
        }

        small.investing {
          display: block;
          font-size: 0.75rem;
          color: $brand-grey;
        }

        .histogram {
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding: 0 0.5rem;
        }

        .folio-stats {
          background-color: #202832;
          border-radius: 0.5rem;
          padding: 0.5rem;
          margin: 0.5rem;
          text-align: center;

          .folio-stat {
            strong {
              font-size: 1rem;
            }

            small {
              font-size: 0.75rem;
              display: block;
              color: $brand-grey;
            }
          }
        }
      }
    }

    .get-started {
      text-align: center;
      padding-top: 5rem;
      padding-bottom: 5rem;

      h2 {
        margin-bottom: 2rem;
      }
      p {
        margin-bottom: 3rem;
      }
      .btn {
        background-color: $brand-green;
        border-color: $brand-green;
        padding: 1rem 3rem;
        font-weight: 600;

        &:hover {
          background-color: $brand-orange;
          border-color: $brand-orange;
        }
      }
    }

    .footer {
      background-color: #0c121b;
      border-top: 1px solid #202832;
      padding-top: 4rem;
      padding-bottom: 4rem;

      .timeline-Tweet {
        padding: 0.5rem 1rem 0 0;
      }

      p {
        margin-bottom: 2rem;
      }

      .btn {
        color: $pure-white;
        background-color: #5865f2;
        border-color: #5865f2;
        padding: 1rem 2rem;
        font-weight: 600;

        svg {
          margin-right: 0.25rem;
        }

        &:hover {
          color: #5865f2;
          background-color: $pure-white;
          border-color: $pure-white;
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .explore {
    &.asset-detail {
      .main {
        .asset-info {
          .asset-stat {
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .explore {
    &.asset-detail {
      .main {
        .asset-info {
          .asset-stat {
            h3 {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .explore {
    &.asset-detail {
      .main {
        .asset-info {
          .asset-stat {
            h3 {
              font-size: 3.5rem;
            }
          }
        }
      }
    }
  }
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
