// Extra small devices (portrait phones, less than 576px)

.profile {
  dt {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .user-preferences,
  .user-portfolios,
  .user-institutions {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .profile-link {
    margin-bottom: 0;
    padding: 0;
    font-size: 0.875rem;
    display: inline-block;

    a {
      color: rgba($pure-white, 0.75);
      transition: color 0.15s ease-in-out;

      &:hover {
        color: $brand-orange;
      }
    }
  }

  .ftu {
    min-height: 120px;

    .btn-warning {
      color: $pure-white;
      background-color: $brand-orange;
      border-color: $brand-orange;
      margin-right: 0.75rem;
      font-weight: 600;

      &:hover {
        background-color: #324255;
        border-color: rgba($pure-white, 0.5);
      }
    }
  }

  .card-title {
    font-weight: 600;

    .easy-edit-wrapper {
      width: 100%;
    }

    .easy-edit-inline-wrapper {
      width: 100%;
    }
  }

  .easy-edit-wrapper {
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: rgba($pure-white, 0.75);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 90%;

    &.easy-edit-not-allowed {
      width: fit-content;
      cursor: not-allowed;

      &.user-id-ref {
        color: #6c757d !important;
        font-style: italic;
      }
    }

    &.easy-edit-hover-on {
      cursor: pointer;
      font-style: initial;
      color: rgba($pure-white, 1);
    }
  }

  .easy-edit-inline-wrapper {
    display: inline-block;
    width: 90%;

    .easy-edit-component-wrapper {
      display: inline-grid;
      width: 100%;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .form-control {
        background-color: $brand-grey-dark;
        outline: none;
        color: $pure-white;
        font-size: inherit;
        font-weight: inherit;
        border-color: $brand-grey-dark;
      }

      .form-select {
        color: $pure-white;
        // background: $brand-grey-dark;
        border: 1px solid $brand-grey-dark;
      }

      .easy-edit-textarea {
        min-height: 40px;
      }

      .easy-edit-checkbox-label,
      .easy-edit-radio-label {
        display: block;
      }
    }

    .easy-edit-button-wrapper {
      display: inline;

      .easy-edit-button {
        border: none;
        display: inline-block;
        margin: 0 2px;
        padding: 4px;
        text-align: center;
        text-decoration: none;
      }
    }

    .easy-edit-instructions {
      font-size: 0.75rem;
    }

    .easy-edit-validation-error {
      color: red;
    }
  }

  .port {
    &.card {
      background-color: transparent;
      border: 1px solid rgba($brand-grey-light, 0.25);
    }
  }

  .institution {
    &.card {
      background-color: transparent;
      border: 1px solid rgba($brand-grey-light, 0.25);
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
