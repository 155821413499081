// Extra small devices (portrait phones, less than 576px)

.signup,
.login,
.update-profile,
.forgot-password,
.MC2eD1A3 {
  color: $pure-white;

  .card {
    color: $basic-blue;
  }

  a {
    color: $brand-grey-light;

    &:hover {
      color: $brand-orange;
    }
  }

  input.form-control {
    @include dark-input;
  }

  button[type='submit'] {
    background-color: $brand-green;
    border: none;
    font-weight: bold;
  }
}

.auth-sidebar {
  background-image: url(../../media/textured-background.png);
  border-left: 1px solid #161616;
}

.sidebar-quote {
  font-weight: normal;
  font-style: italic;
}

.auth-logo {
  width: 200px;
}

.GoogleLoginButton,
.TwitterLoginButton,
.FacebookLoginButton {
  margin: 1rem 0 0 0 !important;
  width: auto !important;
  min-width: 250px !important;
  padding: 0px 1rem !important;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
