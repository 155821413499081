.asset-bullpen {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;

  .bullpen-asset {
    margin: 0 0 1rem 0;
    padding: 0;
    width: calc(50% - 0.4688rem);
    color: $pure-white;
    text-align: center;

    &:empty {
      display: none;
    }

    .asset-wrapper {
      transform: translate3d(0, 0, 0);
      height: 100%;
      justify-content: center;

      .asset {
        align-items: center;
        cursor: no-drop;
        padding: 0 0.5rem 0;
        margin: 1px;
        background-color: $roster-card-bg;
        border: 1px solid rgba($pure-white, 0.05);
        height: 100%;
        width: 100%;
        border-radius: 10px;

        .asset-menu {
          position: absolute;
          right: 0.25rem;
          top: 0.25rem;

          .dropdown-toggle::after {
            display: none;
          }

          .btn-link {
            font-weight: 400;
            color: $pure-white;
            text-decoration: none;
          }

          .dropdown-menu-arrow {
            top: -25px;
            left: 86%;
            width: 0;
            height: 0;
            position: relative;
          }
          .dropdown-menu-arrow:before,
          .dropdown-menu-arrow:after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-width: 7px 8px;
            border-style: solid;
            border-color: transparent;
            z-index: 1001;
          }
          .dropdown-menu-arrow:after {
            bottom: -18px;
            right: -8px;
            border-bottom-color: $pure-white;
          }
          .dropdown-menu-arrow:before {
            bottom: -17px;
            right: -8px;
            border-bottom-color: rgba($pure-black, 0.15);
          }
        }

        .asset-grip {
          display: block;
          opacity: 0;
          margin: 0.375rem auto;
          color: $roster-card-grip;
        }

        .icon-container {
          min-height: 96px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          align-content: center;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .asset-icon {
          border-radius: 5px;
          margin-top: 0;
          margin-bottom: 1.25rem;
          max-width: 60px;

          // &.young {
          //   filter: grayscale(1);
          // }
        }

        .asset-symbol {
          cursor: pointer;
          margin-bottom: 0;
          font-weight: bold;
          font-size: 1.25rem;
        }

        .asset-name {
          cursor: pointer;
          display: block;
          margin-bottom: 1rem;
          color: $brand-grey;
          font-size: 90%;
        }

        .asset-correlation {
          display: block;
          margin-bottom: 1rem;
          font-size: 0.875rem;
          color: $brand-yellow;
          font-weight: bold;
        }

        .young-asset {
          .histogram {
            filter: grayscale(1) blur(3px);
            padding: 2rem 1.25rem 0.5rem;
          }

          .message {
            margin: 0;
            padding: 1rem 0;
            font-size: 70%;
            // Background Blur using backdrop-filter
            background: rgba($pure-white, 0);
            backdrop-filter: blur(4px); // This be the blur

            p {
              padding: 0.25rem;

              margin-bottom: 0;
            }
          }

          .btn-link {
            color: $pure-white;
            margin-bottom: 0.5rem;
            text-decoration: underline;
            color: $brand-grey;
            font-size: 0.75rem;
          }
        }

        &.draggable {
          cursor: move;

          .asset-grip {
            opacity: 1;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .asset-bullpen {
    .bullpen-asset {
      width: calc(100%);

      .asset-wrapper {
        .asset {
          .asset-name {
            display: block;
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .asset-bullpen {
    .bullpen-asset {
      width: calc(100%);

      .asset-wrapper {
        .asset {
          .asset-name {
            display: none;
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .asset-bullpen {
    .bullpen-asset {
      width: calc(50% - 0.4688rem);

      .asset-wrapper {
        .asset {
          .asset-name {
            display: block;
          }
        }
      }
    }
  }
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
