// Extra small devices (portrait phones, less than 576px)

// styles for investors
.investors {
  overflow: visible;

  .main {
    .investor-info {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .investor-toolbar {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;

      .dropdown {
        .btn-dark {
          color: rgba($pure-white, 1);
          background-color: rgba($pure-white, 0.05);
          border-color: rgba($pure-white, 0.05);
        }

        &.show {
          .btn-dark {
            color: rgba($pure-white, 1);
            background-color: #324255;
            border-color: #324255;
          }
        }

        .btn-dark {
          color: rgba($pure-white, 1);
          background-color: rgba($pure-white, 0.05);
          border-color: rgba($pure-white, 0.05);

          &:hover {
            color: rgba($pure-white, 1);
            background-color: #324255;
            border-color: #324255;
          }
        }
      }
    }

    .investor-cards {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .card {
        background-color: rgba(19, 26, 36, 1);
        border: 1px solid rgba($pure-white, 0.05);
        border-radius: 10px;
        height: 100%;
        opacity: 1;

        h3 {
          text-shadow: 0 0 10px $pure-white;
          color: transparent;
        }

        &.investor {
          .card-body {
            text-align: center;
            padding: 1rem 1rem 0;

            .investor-name {
              margin-bottom: 0;
              font-weight: bold;
              font-size: 1rem;
              color: rgba($pure-white, 1);
            }
            .investor-url {
              margin-bottom: 0.5rem;
              color: $brand-grey;
              font-size: 0.875rem;
            }

            .investor-stat {
              display: flex;
              align-items: center;
              flex-direction: column;

              .investor-stat__value {
                font-weight: bold;
                font-size: 1.25rem;
                margin-bottom: 0.25rem;
              }

              .investor-stat__label {
                font-size: 0.75rem;
              }

              .investor-stat__divider {
                font-size: 50%;
              }
            }

            .investor-stats {
              margin-top: 1rem;
              margin-bottom: 1rem;
            }

            .investor-holdings {
              border-top: dashed 1px rgba($pure-white, 0.25);
              padding-top: 1rem;
              margin-top: 1.5rem;
              margin-bottom: 1rem;
            }

            .icon-container {
              min-height: 96px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              align-content: center;
              flex-direction: column;
              flex-wrap: nowrap;
            }

            .investor-icon {
              border-radius: 5px;
              margin-top: 0;
              margin-bottom: 1.25rem;
              max-width: 60px;
            }
          }

          .card-footer {
            padding: 0.75rem 1rem 0.75rem;

            .follow-btn {
              width: 100%;
              border: 1px solid rgba($pure-white, 0.5);
              color: $pure-white;
              background-color: #212b39;
              transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

              &:hover:not([disabled]) {
                background-color: #324255;
              }

              &.following {
                background-color: $brand-blue;
                border: 1px solid $brand-blue;

                &:hover:not([disabled]) {
                  background-color: $brand-orange;
                  border: 1px solid $brand-orange;
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar {
    a:not(.dropdown-item) {
      color: rgba($pure-white, 1);

      &:hover {
        color: rgba($pure-white, 1);
      }
    }

    .investor-search {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      .teaser {
        padding: 2rem 1rem;
        border-radius: 10px;
        background-color: rgba($pure-white, 0.1);
        text-align: center;
      }

      .my-investors {
        .edit-investors {
          margin-left: auto;
          color: rgba($pure-white, 1);

          &:hover {
            color: rgba($brand-orange, 1);
          }
        }

        .investors-title {
          display: flex;
          font-size: 1.5rem;
          // font-weight: 300;
          align-items: baseline;
          padding-bottom: 1rem;
          border-bottom: 1px dashed #202832;

          span {
            margin-left: 0.5rem;
            font-size: 0.875rem;
            color: rgba($pure-white, 0.5);
          }

          .btn {
            color: rgba($pure-white, 0.75);
            margin-left: auto;
            line-height: 1;

            &:hover {
              color: rgba($brand-orange, 1);
            }
          }
        }

        .investors-list__labels {
          color: rgba($pure-white, 0.75);
          font-size: 0.75rem;
          margin-bottom: 1rem;
          margin-right: calc(var(--bs-gutter-x) * 0);
          margin-left: calc(var(--bs-gutter-x) * 0);
        }

        ul.investors-list {
          display: flex;
          flex-direction: column;
          list-style: none;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 0;

          li {
            padding: 1rem 0;
            border-radius: 0.5rem;
            background-color: rgba($pure-white, 0.1);
            margin-bottom: 1rem;
            margin-right: calc(var(--bs-gutter-x) * 0);
            margin-left: calc(var(--bs-gutter-x) * 0);
            font-size: 0.75rem;

            .acro-icon {
              width: 3rem;
              height: 3rem;
              margin: 0;
              padding: 1.5rem;
              font-size: 0.75rem;
            }

            .btn {
              color: rgba($pure-white, 0.5);
              padding: 0 !important;
              margin: 0 !important;
              display: block;
              line-height: 1;

              &:hover {
                color: rgba($brand-orange, 1);
              }
            }
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .investors {
    overflow: hidden;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
