.stock-tile {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  min-height: 70px;
  min-width: 70px;
  cursor: pointer;
  text-align: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
  span {
    font-weight: 700;
  }
  small {
    font-size: 0.75em;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
  }
  &.active {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
}

@media (max-width: 768px) {
  .stock-tile {
    min-height: 60px;
    min-width: 60px;
    span {
      font-size: 0.75em;
    }
  }
}
