#popover-basic {
  max-width: fit-content;
  background-color: $blue-blue-bg;
}

.bs-popover-end > .popover-arrow::after {
  border-right-color: $blue-blue-bg;
}

.view-mode-toggle {
  color: $brand-green;
  cursor: pointer;
}

@media (max-width: 768px) {
  .covariance-matrix-btn {
    display: none;
  }
}
