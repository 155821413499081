.ag-theme-alpine {
  .ag-header-cell-label {
    font-size: .8em;
    color: $brand-grey;
    justify-content: center;
  }

  .ag-row-level-0 {
    border-bottom: 1px solid #202832;
  }

  .ag-row .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grid-visual {
    height: 25px;
    min-width: 40px;
  }
}