.shape-of-risk-intro {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 30px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: pointer;
    li {
      margin-bottom: 1rem;
      &:hover,
      &.active {
        color: $brand-green;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
  img {
    border-radius: 2px;
  }
  .content-image {
    max-width: 100%;
    display: block;
  }
}

.isWelcomeSlide {
  background-image: url('../../media/leaderboard.png');
  background-size: cover;
}

.onboarding-welcome {
  h1 {
    font-size: 3.5rem;
    text-transform: uppercase;
    width: 90%;
    margin-bottom: 3rem;
    span {
      color: $brand-orange;
    }
  }
  h4 {
    font-weight: 100;
  }
  button {
    font-size: 1.3rem;
    background-color: $brand-green;
  }
}

.horizontal-nav-container {
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 30px;
  cursor: pointer;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .isActive {
    background-color: $brand-green;
    padding: 5px 10px;
    border-radius: 30px;
    font-weight: bold;
    color: #ffffff;
  }
}

.onboarding-mobile-nav-item {
  cursor: grab;
  white-space: nowrap;
}

.close-modal {
  display: flex;
  margin-left: auto;
  margin-bottom: 0.5rem;
  padding-right: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding-right: 0.75rem;
  }
}
