// Extra small devices (portrait phones, less than 576px)

.help {
  overflow: visible;

  .main {
    .help-info {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .sidebar {
    a:not(.dropdown-item) {
      color: rgba($pure-white, 1);

      &:hover {
        color: rgba($pure-white, 1);
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
