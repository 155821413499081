// Extra small devices (portrait phones, less than 576px)

// styles for portfolio
header {
  &.navbar {
    border-bottom: 1px solid #202832;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .container-fluid {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      justify-content: flex-start;
    }

    .navbar-brand {
      font-family: $font-family-serif;
      font-weight: 700;
    }

    .navbar-text {
      padding-top: 0;
      padding-bottom: 0;

      .portfolio-name {
        margin-bottom: 0;
        color: $pure-white;
        padding: 0.5rem 0.25rem;
        font-size: 1.25rem;
        font-weight: 600;
        border-radius: 0.25rem;
        display: inline-block;

        &.focus-visible {
          background-color: rgba($brand-grey-light, 0.25);
          outline: none;
        }
      }

      .svg-inline--fa {
        color: #ffffff55;
        margin-left: 0.5rem;
        margin-bottom: 0.125rem;
        cursor: pointer;
        font-size: 0.75em;
      }
    }

    .btn-primary {
      color: $pure-white;
      background-color: #212b39;
      border-color: rgba($pure-white, 0.5);
      margin-right: 0.75rem;

      &:hover {
        background-color: #324255;
        border-color: rgba($pure-white, 0.5);
      }
    }

    .btn-warning {
      color: $pure-white;
      background-color: $brand-orange;
      border-color: $brand-orange;
      font-weight: 600;

      &:hover {
        background-color: #324255;
        border-color: rgba($pure-white, 0.5);
      }
    }

    .nav-link {
      color: $pure-white !important;
      background-color: #212b39;
      border: 1px solid rgba($pure-white, 0.5);
      margin-right: 0.75rem;
      padding: 0.375rem 0.75rem !important;
      border-radius: 0.25rem;

      &:hover {
        background-color: #324255;
        border-color: rgba($pure-white, 0.5);
      }

      &.toggle-sidebar {
        border: 1px solid rgba($pure-white, 0);
        background-color: transparent;
        margin-right: 0;
        padding-left: 0 !important;

        &:active {
          background-color: transparent;
          border-color: transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.dropdown-toggle {
        margin-right: 0;
      }

      &.share-cta {
        border-radius: 5rem;
        border: 1px solid rgba($brand-orange, 1);
        background-color: $brand-orange;
        font-weight: 700;
        padding: 0.375rem 1.375rem !important;
        margin-right: 0;
      }
    }

    .dropdown-item {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      &.active {
        background-color: $brand-orange;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  header {
    &.navbar {
      padding-top: 1rem;
      padding-bottom: 1rem;

      .navbar-text {
        .portfolio-name {
          padding: 0.5rem;
          font-size: 1.25rem;
        }
      }
      .nav-link {
        &.toggle-sidebar {
          display: none;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
