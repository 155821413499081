// Extra small devices (portrait phones, less than 576px)
.modal-header {
  .modal-title {
    color: $pure-white;
    svg {
      color: $brand-orange;
    }
  }

  // close icon
  .btn-close {
    color: $pure-white;
  }
}

.modal-body {
  padding-bottom: 2.5rem;
  p:not(.lead) {
    // color: rgba($pure-white, 1);
    display: inline-block;
  }
  // .dropdown {
  //   display: inline-block;
  // }
  .btn-group-toggle {
    display: inline-block;

    .btn-primary {
      background-color: rgba($blue-blue-border, 1);
      border-color: rgba($blue-blue-border, 1);

      &:hover {
        background-color: rgba($blue-bg, 1);
        border-color: rgba($blue-bg, 1);
      }

      &.active {
        background-color: $blue-bg;
        border-color: rgba($blue-bg, 0.25);
      }
    }
  }
}

.modal-footer {
  // justify-content: flex-start;
  .btn-primary {
    background-color: rgba($blue-blue-border, 1);
    border-color: rgba($pure-white, 0.5);

    &:hover {
      background-color: rgba($brand-orange, 1);
      border-color: rgba($brand-orange, 1);
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
