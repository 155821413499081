// - - - - - - - - - - - - - - - -
// REM SIZING CHEATER (0.0625)
// - - - - - - - - - - - - - - - -
// 5px => 0.3125rem
// 8px => 0.5rem
// 9px => 0.5625rem
// 10px => 0.625rem
// 12px => 0.75rem
// 14px => 0.875rem
// 16px => 1rem
// 18px => 1.125rem
// 20px => 1.25rem
// 22px => 1.375rem
// 24px => 1.5rem
// 26px => 1.625rem
// 28px => 1.75rem
// 30px => 1.875rem
// 32px => 2rem
// 34px => 2.125rem
// 35px => 2.1875rem
// 36px => 2.25rem
// 38px => 2.375rem
// 40px => 2.5rem
// 42px => 2.625rem
// 44px => 2.75rem
// 45px => 2.8125rem
// 46px => 2.875rem
// 48px => 3rem
// 50px => 3.125rem
// 55px => 3.4375rem
// 56px => 3.5rem
// 60px => 3.75rem
// 62px => 3.875rem
// 64px => 4rem
// 70px => 4.375rem
// 75px => 4.69rem
// 76px => 4.75rem
// 80px => 5rem
// 100px => 6.25rem
// - - - - - - - - - - - - - - - -

// Extra small devices (portrait phones, less than 576px)

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: $font-family-base;
  font-weight: normal;
  background-color: #0d141e;
  color: $pure-white;
}

// Firebase Emulator Message
// moves this message to not be so obtrusive
.firebase-emulator-warning {
  display: none;
  width: 200px !important;
  background-color: unset !important;
  border: unset !important;
  color: rgb(255, 0, 0) !important;
  // bottom: unset !important;
  // top: 13px !important;
  bottom: 10px !important;
  left: 10px !important;
  text-align: left !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
  line-height: 0.875rem !important;

  background: #ee0979;
  background: -webkit-radial-gradient(
    circle farthest-side at center center,
    #ee0979 0%,
    #ff6a00 100%
  );
  background: -moz-radial-gradient(circle farthest-side at center center, #ee0979 0%, #ff6a00 100%);
  background: radial-gradient(circle farthest-side at center center, #ee0979 0%, #ff6a00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// text

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-sans-serif;
  font-weight: 700;
}

// button
.btn {
  text-decoration: none;
  transition: color 0.2s ease-out, background-color 0.2s ease-out, border-color 0.2s ease-out;

  &:hover {
    text-decoration: none;
  }
}

.btn-primary {
  color: $pure-white;
  background-color: $link-color;
  border-color: $link-color;

  &:not(.active):focus,
  &:not(.active):hover {
    color: $pure-white;
    background-color: $link-accent-color;
    border-color: $link-accent-color;
  }

  &:not(:disabled):not(.disabled):active {
    color: $pure-white;
    background-color: $link-accent-color;
    border-color: $link-accent-color;
  }
}

// bootstrap icon
// will be removed when we move to font awesome
.bi {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -0.125em;
}

// Utility Classes

.bg-orange {
  background-color: $brand-orange;
}

.highlight-orange {
  color: $brand-orange;
}

.grad-dark-vert {
  background: linear-gradient(180deg, #0d141e 0%, #121924 100%);
}

.bg-dark {
  background-color: #111a25 !important;
}

.card {
  small {
    display: block;
    span.public {
      color: $brand-green;
      cursor: copy;
    }
  }
}

.toast {
  z-index: 9999;
  color: #6c757d;

  &.error {
    background-color: rgba($brand-orange, 1);
    color: $pure-white;

    .toast-header {
      color: $pure-white;
      background-color: unset;
    }
  }

  &.info {
    background-color: rgba($brand-blue-light, 1);
    color: $pure-white;

    .toast-header {
      color: $pure-white;
      background-color: unset;
    }
  }

  &.success {
    background-color: rgba($brand-green-dark, 1);
    color: $pure-white;

    .toast-header {
      color: $pure-white;
      background-color: unset;
    }
  }

  // .toast-header {
  //   color: $pure-white;
  //   background-color: $brand-orange;
  //   border-color: $brand-orange;
  // }
  // .toast-body {
  //   color: $pure-white;
  //   background-color: $brand-orange;
  //   border-color: $brand-orange;
  // }
  // .btn-close {
  //   color: $pure-white !important;
  // }

  // &.info {
  //   .toast-header {
  //     color: $pure-white;
  //     background-color: $brand-green;
  //     border-color: $brand-green;
  //   }
  //   .toast-body {
  //     color: $pure-white;
  //     background-color: $brand-green;
  //     border-color: $brand-green;
  //   }
  //   .btn-close {
  //     color: $pure-white !important;
  //   }
  // }
}

.dropdown-toggle::after {
  margin-left: 0.5rem;
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-left: 0.2em solid transparent;
}

.acro-icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: $brand-orange;
  color: rgba($pure-white, 1);
  font-size: 0.5rem;
  font-weight: bold;
  display: flex;
  margin: 1rem auto;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;

  &.green {
    background-color: rgba(113, 128, 75, 1);
    span {
      color: rgba(207, 230, 114, 1);
    }
  }
  &.blue {
    background-color: rgba(67, 124, 133, 1);
    span {
      color: rgba(114, 223, 230, 1);
    }
  }
  &.orange {
    background-color: rgba(124, 84, 75, 1);
    span {
      color: rgba(230, 142, 114, 1);
    }
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

// Stat classes

.stat-positive {
  color: $brand-green-hot;

  &.stat-correlated {
    color: $brand-yellow;
    font-weight: bold;
  }
}

.stat-negative {
  color: $brand-orange;

  &.stat-correlated {
    color: $brand-yellow;
    font-weight: bold;
  }
}

.fourOhFour {
  color: $pure-white;

  h1 {
    font-size: 4rem;
  }

  a {
    color: $pure-white;

    &:hover {
      color: $brand-orange;
    }
  }
}
