// Extra small devices (portrait phones, less than 576px)

.tools {
  .main {
    background-color: #0d141e;

    input,
    select {
      background-color: rgba($pure-white, 0.1);
      border: 1px solid rgba($pure-white, 0.2);
      color: #ffffff;

      &::placeholder {
        color: rgba($pure-white, 0.5);
      }
    }

    .search-n-add {
      padding: 2rem 1.5rem;
      border-radius: 10px;
      background-color: rgba($pure-white, 0.05);
    }

    .table-dark {
      background-color: transparent;
    }

    .stock {
      &.bg-alert {
        background: repeating-linear-gradient(
          45deg,
          rgba(230, 228, 114, 0.15),
          rgba(230, 228, 114, 0.15) 10px,
          rgba(230, 228, 114, 0.1) 10px,
          rgba(230, 228, 114, 0.1) 20px
        );
      }

      td {
        vertical-align: middle !important;
      }

      .btn-toolbar {
        flex-wrap: nowrap;
      }

      .icon {
        border-radius: 5px;
        margin: 0.5rem;
        max-width: 60px;
      }

      a,
      .btn {
        color: $pure-white;
      }
    }
  }

  .sidebar {
    background-color: $blue-blue-bg;
    border-right: 1px solid #202832;

    hr {
      border-top: 1px solid rgba(#202832, 1);
    }

    .nav {
      margin-top: 2rem;

      .nav-link {
        color: rgba($pure-white, 0.75);
        border-top: 1px solid rgba(#202832, 1);
        padding-left: 0;

        &:hover {
          color: $pure-white;
        }

        &.active {
          color: $pure-white;
          background-color: transparent;
          font-weight: 600;

          &:hover {
            color: $pure-white;
            cursor: default;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
