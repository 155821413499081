@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';


.AssetInfoModal {
  min-width: 90%;
  // min-width: 100%;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .AssetInfoModal {
    min-width: 80%;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .AssetInfoModal {
    min-width: 70%;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .AssetInfoModal {
    min-width: 60%;
  }
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  .AssetInfoModal {
    min-width: 50%;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
  .AssetInfoModal {
    min-width: 40%;
  }
}
