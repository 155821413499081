@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.AssetSearch {
  border-radius: 3px;
  max-width: 800px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .AssetSearchSectionHeader {
    background: #131313;
  }

  .ais-SearchBox-input {
    background-color: #131A24;
    border-radius: 10px;
  }
}

.AssetSearchPopover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);

  & > div {
    background-color: #131A24;
  }

  .ag-header-cell-label {
    color: #8C8C8C;
  }

  .ag-header-container {
    padding-left: $spacer * 1.5;
    padding-right: $spacer * 1.5;
  }

  .ag-row {
    border-top: 1px solid #0D141E;
    border-bottom: 1px solid #0D141E;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    padding: $spacer * 1.5;
  }
}
