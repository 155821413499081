.chart-carousel {
  height: auto;
  background-color: transparent;
  width: auto;
  min-width: 100%;
  svg {
    color: rgba($pure-white, 0.5);
  }
}
.d3-chart {
  text-align: center;
  width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 0 1.25rem;

  svg {
    padding-top: 2rem;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: #888;
    font-size: 0.75rem;
  }
}

.stock .d3-chart {
  width: 90%;
}

.carousel-line-chart {
  text-align: center;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding: 0;

  svg {
    padding: none;
    margin-top: 1.5rem;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #888;
    font-size: 0.75rem;
  }
}
