.shape-of-risk-stat {
  align-items: center;
  h2 {
    font-size: 3rem;
  }
  .red {
    color: $brand-orange;
  }
  .yellow {
    color: #fbd76a;
  }
  .green {
    color: $brand-green;
  }
  .description {
    color: #ffffff69;
    align-self: flex-end;
    width: 70%;
  }
}
.has-extra-value {
  cursor: pointer;
  h3 {
    padding-bottom: 1rem;
  }
}
.stats-header {
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #010810;
  padding: 10px 20px;
}
.stats-allocation-header {
  position: absolute;
  top: 10px;
  right: 0;
}
.stat-label {
  color: rgba(154, 154, 154, 0.8);
  font-size: .8em;
  font-weight: 600;
}
.stat-extra {
  font-size: .8em;
  font-weight: bold;
}
.stat {
  min-width: 40%;
}


@include media-breakpoint-up(lg) {
  .stats-allocation-toggle {
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .shape-of-risk-stat {
    align-items: flex-start;
    h2 {
      font-size: 2rem;
    }
    .description>span {
      font-size: .875rem;
    }
  }
  .stock-list-header h4 {
    font-size: 1em;
  }
}

@media (max-width: 1200px) {
  .shape-of-risk-stat {
    align-items: flex-start;
    h2 {
      font-size: 2rem;
    }
    .description>span {
      font-size: .875rem;
    }
  }
}