.axisX text {
  text-anchor: start;
}

.baseGridLine line {
  stroke: rgba(255, 255, 255, 0.5);
}

.d3Tooltip text {
  font-size: 12px;
  fill: $pure-white;
}

.tooltipLine {
  stroke: $pure-white;
  stroke-width: 1px;
}

.tooltipContent .contentTitle {
  font-weight: bold;
  // font-size: 1rem;
}

.legendContainer {
  display: flex;
  justify-content: center;
  color: $pure-white;
}

.checkbox {
  margin: 10px;
}

.new-multi-chart {
  width: 96%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 0;
}

.histo-bottom-axis * {
  font-size: 0.6rem;
  fill: #ffffff;
}
