// Extra small devices (portrait phones, less than 576px)

.stock-source {
  font-size: 0.875rem;
  opacity: 0.25;
  &.active {
    opacity: 1;
    font-weight: 600;
  }
  cursor: pointer;
  &:hover {
    opacity: 1;
    color: #fff;
  }
}

.stock-list {
  background-color: #0b111a;
  height: 320px;
  row-gap: 10px;
}

.hero {
  h1 {
    font-size: 3.25rem;
    line-height: 95%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h2 {
    font-size: 1.4em;
    line-height: 1.4;
  }
}

.wizard {
  min-height: 700px;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
