// Extra small devices (portrait phones, less than 576px)

.plaid-btn {
  line-height: 1.5 !important;
  vertical-align: middle !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid transparent !important;
  text-decoration: none !important;
  border-radius: 0.25rem !important;
  color: #ffffff !important;
  background-color: #00487a !important;
  font-family: $font-family-sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.75rem import !important;
  transition: color 0.2s ease-out, background-color 0.2s ease-out, border-color 0.2s ease-out !important;
}

.plaid-btn:not(.active):hover {
  color: #ffffff !important;
  background-color: #ef5443 !important;
  border-color: #ef5443 !important;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
