// Primary Color Palette

$pure-black:                #222222;
$pure-white:                #ffffff;

$brand-tan:                 #f1f1e6;
$brand-blue-light:          #40afff;
$brand-blue:                #00487A;
$brand-orange:              #F45D48;
$brand-grey:                #757575;
$brand-grey-light:          #b8b8b8;
$brand-grey-dark:           #383d44;
$brand-green:               #1FBD60;
$brand-green-hot:           #62BD37;
$brand-green-dark:          #059B42;
$brand-yellow:              #ECC344;

$basic-blue:                #0d141e;
$blue-bg:                   #2d394b;
$blue-blue-bg:              #0C121B;
$blue-blue-border:          #202832;

$roster-card-bg:            #10151d;
$roster-card-grip:          #444d59;
$dark-button:               #1A2430;

$body-color:                $pure-black;
$link-color:                $brand-blue;
$link-accent-color:         $brand-orange;

// Grid settings
$enable-flex:               true;

// Fonts
$font-family-serif:         "Libre Baskerville", serif;
$font-family-sans-serif:    "Mulish", sans-serif;
$font-family-base:          $font-family-sans-serif;

// Type
$lead-font-size:            1.5rem;

// Bootstrap color system
$primary:                   $brand-orange;
$dark:                      $basic-blue;

// Modal
$modal-inner-padding:               1.5rem;
$modal-content-color:               rgba($pure-white, .5);
$modal-content-bg:                  rgba($blue-blue-bg, 1);
$modal-content-border-color:        rgba($blue-blue-border, 1);
// $modal-backdrop-bg:                 $black !default;
// $modal-backdrop-opacity:            .5 !default;
$modal-header-padding-y:            1.5rem;
$modal-header-padding-x:            1.5rem;
$modal-header-border-color:         rgba($blue-blue-border, 1);
$modal-footer-border-color:         $modal-header-border-color;

$form-select-bg:                    $brand-grey-dark;
$form-select-indicator-color:       $brand-grey-light;

$component-active-bg:               $brand-blue-light;

// Toasts
// $toast-background-color:            rgba($white, .85);
// $toast-border-width:                0;
// $toast-border-color:                rgba(0, 0, 0, .1);
// $toast-border-radius:               $border-radius;
// $toast-box-shadow:                  $box-shadow;

// $toast-header-color:                $gray-600;
// $toast-header-background-color:     rgba($white, .85);
// $toast-header-border-color:         rgba(0, 0, 0, .05);

// react pro sidebar
$sidebar-bg-color: #111A25;
$sidebar-color: #A9A9A9;
$sidebar-width: 220px;
$sidebar-collapsed-width: 80px;
$highlight-color: #FFFFFF;
// $submenu-bg-color: #2b2b2b;
// $submenu-bg-color-collapsed: #2b2b2b;
$icon-bg-color: #111A25;
$icon-size: 44px;
// $submenu-indent: 24px;
// $breakpoint-xs: 480px;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;
// $breakpoint-xxl: 1600px;

// Mixins
@mixin dark-input {
  background-color: rgba($pure-white, 0.05);
  border: 1px solid rgba($pure-white, 0.05);
  padding: 0.5rem 1.7rem;
  color: $pure-white;
}

$theme-colors: (
  // "primary":    $primary,
  // "secondary":  $secondary,
  "success":    $brand-green,
  "info":       #3EA6FF,
  // "warning":    $warning,
  // "danger":     $danger,
  // "light":      $light,
  // "dark":       $dark
);
