.lander {
  h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 4rem;
  }
  p {
    font-family: 'Mulish', sans-serif;
    color: #c4ccda;
  }
  .marker {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .top-container.tall {
    min-height: 380px;
  }
}

.lander.container {
  padding-bottom: 200px;
}

.lander.nav-pills {
  li.nav-item a {
    color: #ffffff;
    border-radius: 2em;
    &.active {
      background-color: $brand-green;
      font-weight: 600;
    }
  }
}

.asset-tile {
  background-color: #010810;
  border-radius: 10px;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 150px;
  background-color: black;
  border-top: 1px solid rgb(48, 48, 48);
  padding: 10px;
  z-index: 99;
}

.sticky-footer-close {
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.article-link {
  // background-color: $brand-tan;
  display: inline-block;
  border-radius: 30px;
  padding: 5px 15px;
  color: rgb(168, 168, 168);
  cursor: pointer;
}

.btn-waitlist {
  background-color: $brand-green;
  font-weight: bold;
}

.stock-tile-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 1px solid #3c3c3c;
  border-radius: 80px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  > svg.fa-check {
    color: #2b2b2b;
  }
  &.selected {
    background-color: $brand-green;
    > svg.fa-check {
      color: #ffffff;
    }
  }
}

.sor-brandbar {
  position: relative;

  .sor-brandbar__links {
    position: absolute;
    right: 0;
    display: inline-block;

    a {
      color: #6c757d !important;
      font-size: 0.875rem;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .lander {
    h1 {
      font-size: 2.5em;
    }
  }
  .sor-brandbar a {
    display: none;
  }
}
