.onboarding {
  background-image: url(../../media/textured-background.png);
  background-size: cover;
  p {
    font-size: 1.1rem;
  }
  button {
    background-color: $brand-green;
  }
  img {
    // box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.02);
  }
  .col-md-6.img {
    margin-right: -30px
  }
  .content-image {
    opacity: 0;
    max-height: 100%;
    border: 1px solid #2f2f2f;
    max-width: 100%;
  }
}

.pagination-indicator {
  background-color: $blue-bg;
  height: 10px;
  width: 10px;
  border-radius: 80px;
  &.active {
    background-color: $brand-green;
  }
}

@media (min-width: 768px) {
  .onboarding .rs-mark {
    margin-top: 6rem;
  }
  .onboarding .content-image {
    margin-top: 75px;
  }
}