// Extra small devices (portrait phones, less than 576px)

#notifications {
  &.popover {
    background-color: $blue-blue-bg;
    border: 1px solid rgba($pure-white, 0.05);
    box-shadow: unset;
    max-width: 276px;
  }

  .popover-arrow::after {
    border-bottom-color: $blue-blue-bg;
  }

  .popover-arrow::before {
    top: 0;
    border-bottom-color: rgba(255, 255, 255, 0.05);
  }

  .popover-body {
    h6 {
      margin-bottom: 1rem;
    }

    .list-group-item {
      padding: 0.75rem 0;
      color: $pure-white;
      background-color: $blue-blue-bg;
      border: unset;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      align-items: flex-start;
      justify-content: space-between;
      display: flex;

      .message {
        margin-right: auto;

        .message__title {
          font-weight: 700;
        }

        .message__body {
          font-size: 90%;
          margin-bottom: 0.25rem;
        }

        .message__date {
          font-style: italic;
          opacity: 40%;
          font-size: 80%;
        }
      }

      .action {
        opacity: 40%;
        margin-left: 0.5rem;

        .btn {
          color: $pure-white;
          opacity: 50%;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 100%;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  #notifications {
    &.popover {
      max-width: 420px;
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
