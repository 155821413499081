.asset-list-item {
  border-bottom: 1px solid #202832;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  .histogram {
    height: 3rem;
  }
}

.stat-sparkline {
  width: 50px;
}