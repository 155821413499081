// searchbox
.ais-SearchBox {
  margin: 1rem 0 2rem 0;
}
.ais-SearchBox-input {
  @include dark-input;
}

// pagination
.ais-Pagination {
  margin: 2rem 0;
}
.ais-Pagination-link {
  background-color: #212b39;
  border-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.2s ease-out;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #324255;
  border-color: rgba(255, 255, 255, 0.5);
}
.ais-Pagination-link:hover,
.ais-Pagination-link:focus {
  background-color: #324255;
  border-color: rgba(255, 255, 255, 0.5);
}

// instant search component
.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

// .aa-Autocomplete {
// }

.aa-Panel {
  z-index: 2000;
}

.aa-Form {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 0;
  color: #ffffff;
}

// search result item
.aa-Item {
  padding: 0;
  cursor: unset;

  // already in collection
  .aa-ItemWrapper {
    padding: 0.3125rem 0.5rem;
    background-color: rgba($brand-grey-light, 0.15);
    border-radius: 0.25rem;
    opacity: 0.5;
    color: $brand-grey-dark;

    .aa-ItemContent {
      cursor: unset;

      .aa-ItemContentBody {
        grid-gap: 2px;
        gap: 2px;

        .aa-ItemContentSubtitle {
          font-size: 0.75em;
        }
      }
    }

    .aa-ItemActions {
      margin: 0;
      padding-right: 2px;
      font-size: 0.75rem;
    }
  }

  // not in collection
  .aa-ItemLink {
    padding: 0.3125rem 0.5rem;
    background-color: rgba($brand-grey-light, 0);
    border-radius: 0.25rem;
    opacity: 1;
    color: $brand-grey-dark;

    .aa-ItemContent {
      cursor: pointer;

      .aa-ItemContentBody {
        grid-gap: 2px;
        gap: 2px;

        .aa-ItemContentSubtitle {
          font-size: 0.75em;

          span:not(:first-child) {
            &:before {
              content: ' | ';
              margin: 0 0.25rem;
            }
          }

          .stat-negative,
          .stat-positive {
            color: $brand-grey;
          }
        }
      }
    }
  }
}

// hovering over search result item
.aa-Item[aria-selected='true'] {
  background-color: rgba($brand-grey-light, 0);

  // already in collection
  .aa-ItemWrapper {
    cursor: not-allowed;
  }

  // not in collection
  .aa-ItemLink {
    background-color: rgba($brand-grey, 0.1);
    color: $pure-black;

    .aa-ItemContent {
      cursor: pointer;

      .aa-ItemContentBody {
        .aa-ItemContentSubtitle {
          .stat-positive {
            color: $brand-green-hot;
          }
          .stat-negative {
            color: $brand-orange;
          }
        }
      }
    }
  }
}

.aa-Input {
  color: $pure-white;
}

:root {
  --aa-search-input-height: 38px;
  --aa-primary-color-rgb: 0, 72, 122;
  --aa-primary-color-alpha: 0.2;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
