.chart-copy-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  // height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  transition: all 1s ease-in-out;
  z-index: 1;
}

.chart-copy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  p {
    align-self: flex-end;
    font-size: 0.75rem;
    opacity: 0.2;
  }
  cursor: copy;
}
