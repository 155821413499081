@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.PortfolioEditPane {
  input, textarea {
    background-color: #131A24;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 0.5rem;
    color: white;
  }
}
