.btn-dark {
  background-color: #1A2430;
  border: 1px solid #1A2430;
  color: white;
  &:hover {
    background-color: #1A2430;
    border: 1px solid #212b38;
    color: white;
  }
}
