.stock-stats {
  background-color: #151C25;
  flex-shrink: 1;
  border-radius: .5rem;
  div {
    margin: 0 1rem;
  }
}

.stock-stat {
  span:nth-child(1) {
    font-weight: 900;
    font-size: 1.5rem;
  }
  span:nth-child(2) {
    font-size: 0.875rem;
  }
  display: flex;
  flex-direction: column;
}