// Extra small devices (portrait phones, less than 576px)

// styles for portfolio
.portfolio {
  overflow: visible;

  &.shared {
    overflow: visible;

    .main {
      overflow-y: unset;
      padding-top: 1rem;
      padding-right: calc(var(--bs-gutter-x) * 0);
      padding-left: calc(var(--bs-gutter-x) * 0);
    }
  }

  .portfolio-toolbar {
    padding-top: 2rem;
    padding-bottom: 0;

    .switch-label {
      font-size: 1.125rem;
      margin-left: 1rem;
      letter-spacing: 0.05rem;

      small {
        font-size: 0.875rem;
        display: block;
        letter-spacing: 0;
      }
    }

    .align-content-center {
      justify-content: center;
    }

    .portfolio-button {
      margin: 0.313rem;

      @media screen and (max-width: 1195px) {
        margin-top: 0.50rem !important;
      }
    }

    .btn-primary:disabled,
    .btn-primary.disabled {
      color: #000;
      background-color: $brand-grey-dark;
      border-color: $brand-grey-dark;
    }
  }

  .portfolio-info {
    border-bottom: 1px solid #202832;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    // background-color: #111a25;

    h2 {
      margin-bottom: 1.5rem;
    }

    .m-3 {
      white-space: nowrap;

      @media screen and (max-width: 992px) {
        white-space: normal;
      }
    }

    .portfolio-info-nav {
      text-transform: uppercase;
      background-color: transparent;
      width: fit-content;
      border-radius: 0;

      &.nav-pills {
        .nav-item {
          .nav-link {
            color: rgba($pure-white, 0.35);
            display: block;
            padding: 0.375rem 0 0.125rem 0;
            margin: 0 1rem;
            border: none;
            border-radius: 0;
            background-color: transparent;
            font-size: 0.875rem;
            white-space: nowrap;

            @media screen and (max-width: 1200px) {
              margin: 0rem;
            }

            &:hover {
              color: rgba($pure-white, 0.75);
            }
          }

          &:first-of-type {
            .nav-link {
              margin-left: 0;
            }
          }
        }
      }

      // active
      &.nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: rgba($pure-white, 1);
        background-color: transparent;
        border: none;
        border-bottom: 2px solid rgba($brand-green, 1);
        border-radius: 0;
      }
    }

    .portfolio-info-section {
      margin-top: 2rem;
      padding: 1rem 0;

      h3 {
        font-size: 3.25rem;
        font-weight: 900;
      }

      .lead {
        font-size: 1rem;
        font-weight: 900;

        @media screen and (max-width: 1375px) {
          font-size: 0.7rem;
        }
      }

      .overview-line-chart {
        width: 100px;
        margin: 0;
        padding: 0;

        svg {
          margin: 0;
          padding: 0;
        }
      }

      .growth-line-chart {
        width: 100px;
        margin: 0 0 0 2rem;
        padding: 0;
        display: inline-block;

        svg {
          margin: 0;
          padding: 0;
        }
      }

      .multi-line-chart {
        width: 100%;
        margin: 0;
        padding: 0;

        svg {
          margin: 0;
          padding: 0;
        }
      }

      .riskEff {
        h3 {
          color: rgba($brand-green-hot, 1);
          &.stat-negative {
            color: #f45d48;
          }

          @media screen and (max-width: 1300px) {
            font-size: 3rem;
          }
          @media screen and (max-width: 1200px) {
            font-size: 2rem;
          }
        }
      }
      .dScore {
        h3 {
          color: rgba($brand-green-dark, 1);
          &.stat-negative {
            color: #f45d48;
          }
        }

        @media screen and (max-width: 1300px) {
          font-size: 3rem;
        }
        @media screen and (max-width: 1200px) {
          font-size: 2rem;
        }
      }
      .growth {
        h3 {
          color: rgba($brand-green-hot, 1);
          &.stat-negative {
            color: #f45d48;
          }
        }

        h4 {
          color: rgba($brand-green-hot, 0.3);
          &.stat-negative {
            color: #f45d48;
          }
          font-size: 2.875rem;
          font-weight: 600;
        }
      }
    }
  }

  .portfolio-cards {
    padding-top: 2rem;
    padding-bottom: 1.5rem;

    &.dropzone {
      background: repeating-linear-gradient(
        45deg,
        rgba(114, 223, 230, 0.05),
        rgba(114, 223, 230, 0.05) 10px,
        rgba(114, 223, 230, 0.1) 10px,
        rgba(114, 223, 230, 0.1) 20px
      );
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      .card {
        opacity: 0.75;
      }

      .drop-message {
        opacity: 1;
        visibility: visible;
        display: block;

        .drop-here {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          z-index: 1000;
          border: 2px dashed rgba($pure-white, 0.5);
          border-radius: 10px;

          p {
            margin-bottom: 0.5rem;
          }

          svg {
            color: rgba($pure-white, 0.5);
          }
        }
      }
    }

    .card {
      background-color: rgba(19, 26, 36, 1);
      border: 1px solid rgba($pure-white, 0.05);
      border-radius: 10px;
      height: 100%;
      opacity: 1;
      transition: border 0.2s ease-in-out;

      h3 {
        text-shadow: 0 0 10px $pure-white;
        color: transparent;
      }

      &.stock {
        // &:hover {
        //   border: 1px solid rgba($pure-white, 0.25);
        // }

        .card-body {
          // cursor: move;
          text-align: center;
          padding: 0 0.5rem 0;

          .company-icon-container {
            min-height: 96px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            align-content: center;
            flex-direction: column;
            flex-wrap: nowrap;

            img {
              border-radius: 5px;
              margin-top: 1rem;
              margin-bottom: 1.25rem;
              max-width: 60px;
            }
          }

          .text-symbol {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 1.25rem;

            a {
              color: rgba($pure-white, 1);
              text-decoration: none;
            }
          }
          .text-name {
            margin-bottom: 0.5rem;
            color: $brand-grey;
            font-size: 1rem;
          }
          .qty {
            font-size: 0.875rem;
            color: $brand-grey-light;

            .asset-label {
              font-size: 0.675rem;
              display: inline;
              color: $brand-grey;
            }

            .easy-edit-wrapper {
              display: inline-block;
              height: calc(1.5em + 0.25rem + 2px);
              padding: 0.25rem 0.25rem;
              line-height: 1.5;
              color: rgba($brand-green, 1);
              background-color: transparent;
              background-clip: padding-box;
              border: 1px solid transparent;
              border-radius: 0.25rem;
              width: fit-content;
              border-bottom: $brand-green 1px dotted;

              &.easy-edit-not-allowed {
                width: fit-content;
                cursor: not-allowed;
              }

              &.easy-edit-hover-on {
                cursor: pointer;
                font-style: initial;
                color: rgba($brand-green, 1);
              }
            }

            .easy-edit-inline-wrapper {
              display: inline-block;
              // width: fit-content;

              .easy-edit-component-wrapper {
                display: inline-grid;
                // width: fit-content;
                margin-right: 0.25rem;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                input[type='number'] {
                  -moz-appearance: textfield;
                }

                .form-control {
                  background-color: $brand-grey-dark;
                  outline: none;
                  color: $pure-white;
                  font-size: inherit;
                  font-weight: inherit;
                  border-color: $brand-grey-dark;
                  height: calc(1.5em + 0.25rem + 2px);
                  padding: 0.25rem 0.25rem;
                  width: 3rem;
                  text-align: center;
                }
              }

              .easy-edit-button-wrapper {
                display: inline;

                .easy-edit-button {
                  border: none;
                  display: inline-block;
                  margin: 0 2px;
                  padding: 4px;
                  text-align: center;
                  text-decoration: none;
                }
              }

              .easy-edit-instructions {
                font-size: 0.75rem;
              }

              .easy-edit-validation-error {
                color: red;
              }
            }
          }
        }

        .card-footer {
          padding: 0.75rem 1rem 0.75rem;

          .btn-link {
            color: $brand-grey-light;

            &:hover {
              color: $brand-orange;
            }
          }
        }

        &.no-data {
          background-color: rgba($brand-orange, 0.05);
        }
      }
    }

    .drop-message {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  .portfolio-list {
    .list-actions {
      padding: 4px;

      .btn-link {
        color: $brand-grey-light;

        &:hover {
          color: $brand-orange;
        }
      }
    }
  }

  .get-started {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2 {
      margin-bottom: 2rem;
    }
    p {
      margin-bottom: 3rem;
    }
    .btn {
      background-color: $brand-green;
      border-color: $brand-green;
      padding: 1rem 3rem;
      font-weight: 600;

      &:hover {
        background-color: $brand-orange;
        border-color: $brand-orange;
      }
    }
  }

  .footer {
    background-color: #0c121b;
    border-top: 1px solid #202832;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .timeline-Tweet {
      padding: 0.5rem 1rem 0 0;
    }

    p {
      margin-bottom: 2rem;
    }

    .btn {
      color: $pure-white;
      background-color: #5865f2;
      border-color: #5865f2;
      padding: 1rem 2rem;
      font-weight: 600;

      svg {
        margin-right: 0.25rem;
      }

      &:hover {
        color: #5865f2;
        background-color: $pure-white;
        border-color: $pure-white;
      }
    }
  }

  .portfolio-asset-table {
    td {
      font-size: .9em;
    }
  }

  .sidebar {
    a:not(.dropdown-item) {
      color: rgba($pure-white, 1);

      &:hover {
        color: rgba($pure-white, 1);
      }
    }

    .stock {
      h4 {
        a {
          text-decoration: none;
        }
      }
    }

    .pill {
      color: white;
      background-color: $brand-green;
      border-radius: 16px;
      padding: 3px 6px;
      font-size: 0.8em;
      font-weight: bold;
    }
  }

  .notes {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1.5rem;

    hr {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.stock-alloc {
  position: absolute;
  right: 0;
  background-color: $brand-blue;
  border-radius: 0px 10px;
  font-size: 0.875em;
  font-weight: 600;
}

.history-alert {
  position: absolute;
  left: 10px;
  top: 10px;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .portfolio {
    overflow: hidden;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
