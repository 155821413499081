// Extra small devices (portrait phones, less than 576px)

.collections {
  overflow: visible;

  .tooltip {
    opacity: 1;

    .tooltip-arrow {
      &::before {
        top: -1px;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: rgba($pure-white, 1);
      }
    }

    .tooltip-inner {
      padding: 0.375rem 0.5rem;
      color: rgba($brand-grey-dark, 1);
      text-align: center;
      background-color: rgba($pure-white, 1);
    }
  }

  .filters-popover {
    max-width: 300px;
    min-width: 300px;

    // .popover-header {
    //   background-color: unset;
    //   font-weight: bold;
    //   display: flex;

    //   &::before {
    //     display: none;
    //   }

    //   h6 {
    //     margin-top: auto;
    //     margin-bottom: auto;
    //   }
    // }
    .popover-body {
      // padding: 1rem 0;
      // font-size: unset;
      // display: flex;

      // > div {
      //   // padding: 0 1rem;
      //   margin: 0;
      //   width: 100%;
      // }

      p {
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      form {
        margin-bottom: 1rem;
        margin-left: 1px;

        .form-check-input {
          width: 1.25rem;
          height: 1.25rem;
          margin-top: 0.125rem;
        }

        .form-check-label {
          margin-top: 0.125rem;
          padding-left: 0.25rem;
        }
      }

      .filters-popover__actions {
        margin-top: 3rem;
        display: flex;

        .filters-popover__actions--save {
          margin-left: 0.5rem;
          width: 100%;

          &:hover {
            // color: rgba($brand-orange, 1);
            background-color: $brand-orange;
            border: 1px solid $brand-orange;
          }
        }

        .filters-popover__actions--clear {
          margin-right: 0.5rem;
          width: 100%;

          &:hover {
            // color: rgba($brand-orange, 1);
            background-color: $brand-orange;
            border: 1px solid $brand-orange;
          }
        }
      }
    }
  }

  .ranger {
    margin: 0 0 2.5rem 0;
    padding: 0 0.75rem;
    min-width: 250px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .ranger-track {
    display: inline-block;
    height: 6px;
    width: 100%;
    margin: 0;
  }

  .ranger-tick {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      background: rgba($pure-white, 0.25);
      height: 6px;
      width: 1px;
      transform: translate(-50%, 0.6rem);
    }
  }

  .ranger-tick-label {
    position: absolute;
    font-size: 0.6rem;
    color: rgba($pure-white, 0.35);
    top: 100%;
    transform: translate(-50%, 1.2rem);
    white-space: nowrap;
  }

  .ranger-segment {
    height: 100%;
  }

  .ranger-handle {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.25rem;
    font-size: 0.7rem;
    white-space: nowrap;
    font-weight: normal;
    transform: translateY(0) scale(0.9);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.active {
      font-weight: bold;
      transform: translateY(0) scale(1.1);
    }
  }

  .sidebar {
    .collections {
      .list-group {
        margin-top: 3rem;
        border-top: 1px solid rgba(255, 255, 255, 0.125);

        .list-group-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          background-color: transparent;
          border-bottom: 1px solid rgba(255, 255, 255, 0.125);
          color: $pure-white;
          transition: background-color 0.2s ease-in-out;
          cursor: pointer;
          border-left: 1px solid rgba($brand-green, 0);
          padding: 0.5rem 0 0.5rem 1rem;

          .collection__name {
            line-height: 1.675;
            padding: 0.375rem 0;
            font-size: 1rem;
          }

          .btn {
            display: none;
          }

          &.active {
            border-left: 1px solid rgba($brand-green, 1);
            margin-top: 0;
            border-top-width: 0;

            // .btn {
            //   display: inline-block;
            // }
          }

          &:hover {
            background-color: rgba($brand-green-dark, 0.05);

            .btn {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .toolbar {
    .toolbar__title {
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }

    .toolbar__search {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      > div {
        flex: auto;
      }

      .btn-filters {
        margin-left: 0.5rem;

        &.active {
          background-color: $brand-blue-light;
          border: 1px solid $brand-blue;
        }
      }

      .popover-body {
        font-size: unset;
      }
    }

    .toolbar__filters {
      margin-bottom: 1rem;

      .btn-filter {
        margin-right: 0.5rem;
      }
    }

    .toolbar__actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      a {
        text-decoration: none;
      }

      strong {
        color: $brand-yellow;
      }
    }
  }
  .collection {
    padding-bottom: 3rem;

    .collection__details {
      .collection__details-name {
        font-weight: 700;
        font-size: 1.5rem;
        margin-top: 1rem;
      }
      .collection__details-note {
        color: #757575;
        margin-bottom: 2rem;
        font-size: 0.8rem;
      }
    }

    .table {
      margin-top: 1rem;
      border-top: 1px solid rgba(255, 255, 255, 0.125);

      // .bs-tooltip-auto[data-popper-placement^='top'],
      // .bs-tooltip-auto[data-popper-placement^='bottom'],
      // .bs-tooltip-auto[data-popper-placement^='left'],
      // .bs-tooltip-auto[data-popper-placement^='right'] {
      //   padding: 0;
      // }

      // .bs-tooltip-top[data-popper-placement^='top'],
      // .bs-tooltip-top[data-popper-placement^='bottom'],
      // .bs-tooltip-top[data-popper-placement^='left'],
      // .bs-tooltip-top[data-popper-placement^='right'] {
      //   padding: 0;
      // }

      thead {
        tr.asset {
          border-bottom: 1px solid rgba(255, 255, 255, 0.125);

          th {
            vertical-align: middle;
            font-size: 0.85rem;
            align-self: center;
            font-weight: 400;
            color: $roster-card-grip;

            .btn {
              padding-left: 0;
              padding-right: 0;
              color: $brand-grey;

              &:hover {
                color: $brand-grey-light;
              }
            }

            &.asset__symbol {
              width: 22%;
            }
            &.asset__stat {
              width: 12%;
              text-align: center;
            }
            &.asset__histo {
              width: 20%;
              text-align: center;
            }
            &.asset__delete {
              width: 10%;
              text-align: center;
            }
          }
        }
      }
      tbody {
        tr.asset {
          border-bottom: 1px solid rgba(255, 255, 255, 0.125);
          transition: 0.3s ease-in-out;

          &.in-portfolio {
            cursor: not-allowed;

            &:hover {
              --bs-table-accent-bg: unset;
            }

            td {
              background-color: rgba($brand-green-dark, 0.075);

              &.asset__delete {
                .btn {
                  &:disabled {
                    opacity: 15%;
                  }
                }
              }
            }
          }

          td {
            vertical-align: middle;
            font-size: 0.85rem;
            align-self: center;
            transition: 0.3s ease-in-out;

            .asset__stat-loader {
              div {
                margin: auto;
              }
            }

            &.asset__symbol {
              align-self: center;
              font-weight: 700;
              font-size: 1rem;

              span {
                display: block;
                color: $brand-grey-light;
                font-weight: 400;
                font-size: 0.75rem;
              }

              a {
                color: $pure-white;
                text-decoration: none;
              }
            }

            &.asset__stat {
              font-size: 0.8rem;
              align-self: center;
              text-align: center;
            }

            &.asset__histo {
              text-align: center;
              // width: 100px;
              margin-top: 0.25rem;
              margin-bottom: 0;
              align-self: center;
            }

            &.asset__delete {
              justify-self: center;
              align-self: center;
              text-align: center;
              padding-right: 0;
              // padding-left: 0;

              .btn {
                color: $brand-grey;

                &.btn-add {
                  &:hover {
                    color: $brand-green;
                  }
                }

                &:hover {
                  color: $brand-orange;
                }
              }
            }
          }
        }
      }
    }

    .easy-edit-wrapper {
      display: inline-block;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: rgba($pure-white, 1);
      background-color: transparent;
      background-clip: padding-box;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      width: 100%;

      &.easy-edit-not-allowed {
        width: fit-content;
        cursor: not-allowed;

        &.user-id-ref {
          color: #6c757d !important;
          font-style: italic;
        }
      }

      &.easy-edit-hover-on {
        // cursor: pointer;
        cursor: text;
        font-style: initial;
        color: rgba($pure-white, 1);

        // if we can add font awesome to css at some point that'd be cool
        // &::after {
        //   content: '\00a0';
        //   display: inline-block;
        //   width: 0;
        //   height: 0;
        //   border-top: 0.3em solid;
        //   border-right: 0.3em solid transparent;
        //   border-left: 0.3em solid transparent;
        //   margin-left: 0.3em;
        //   margin-top: -0.3em;
        // }
      }
    }

    .easy-edit-inline-wrapper {
      display: inline-block;
      width: 100%;

      .easy-edit-component-wrapper {
        display: inline-grid;
        width: 100%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }

        .form-control {
          background-color: $brand-grey-dark;
          outline: none;
          color: $pure-white;
          font-size: inherit;
          font-weight: inherit;
          border-color: $brand-grey-dark;
        }

        .form-select {
          color: $pure-white;
          // background: $brand-grey-dark;
          border: 1px solid $brand-grey-dark;
        }

        .easy-edit-textarea {
          min-height: 40px;
        }

        .easy-edit-checkbox-label,
        .easy-edit-radio-label {
          display: block;
        }
      }

      .easy-edit-button-wrapper {
        display: inline;

        .easy-edit-button {
          border: none;
          display: inline-block;
          margin: 0 2px;
          padding: 4px;
          text-align: center;
          text-decoration: none;
        }
      }

      .easy-edit-instructions {
        font-size: 0.75rem;
      }

      .easy-edit-validation-error {
        color: red;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}

// X-large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}

// XX-Large devices (larger desktops, 1400px and up)
@include media-breakpoint-up(xxl) {
}

// Print only query
@media print {
}
