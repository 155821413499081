.article-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 20px 20px 50px;
  overflow: hidden;
  margin: 10px;
  border-radius: 3px;
  &:hover {
    color: #ffffff;
    svg {
      color: $brand-green;
    }
  }
}

.article-tile-title {
  font-size: 1.5rem;
  font-weight: 900;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  line-height: 1.3em;
}

.article-tile-teaser {
  display: flex;
  align-items: center;
  svg {
    color: rgba(255, 255, 255, 0.5);
  }
}

.article-tile:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent #ffffff transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}