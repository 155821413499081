.popover {
  background-color: $blue-bg;
  box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.01);
  border: 1px solid rgba($pure-white, 0.05);
  z-index: 3000;
}

.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: $blue-bg;
}

.popover-body {
  color: #fff;
  font-size: 0.75rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.popover-trigger {
  cursor: pointer;
}

.popover-header {
  background-color: unset;
}
